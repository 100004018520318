import { render, staticRenderFns } from "./TourismFilter.vue?vue&type=template&id=baa1d5d0&scoped=true&"
import script from "./TourismFilter.vue?vue&type=script&lang=js&"
export * from "./TourismFilter.vue?vue&type=script&lang=js&"
import style0 from "./TourismFilter.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./TourismFilter.vue?vue&type=style&index=1&id=baa1d5d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baa1d5d0",
  null
  
)

export default component.exports