<template>
  <section>
    <div class="mobile-filter-buttons my10">
      <button 
        class="primary-btn d-flex align-items-center mx5"
        @click="openFilterModal"
      >
        {{ $t('REPORT_SALES.SEARCH') }}
        <img src="/assets/img/icons/search-light-white.svg" alt="" class="seach-btn-img">
      </button>
    </div>
    <section class="filter desktop-filter" :class="hasTypeDeal ? '' : 'no-type-deal'">
      <div class="filter-item deal-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/umbrella-beach-light.svg" alt="">
          <p class="mx5">{{ $t("TOURISM.TYPE_OF_DEAL") }}</p>
        </label>
        <vue-select
          :options="deal.options"
          class="input cus-select border-option-dropdown bd-radius"
          v-model="deal.selected"
          :placeholder="$t('COMMON.SELECT')"
        ></vue-select>  
      </div>
      <div class="filter-item from-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx5">{{ $t("TOURISM.FROM") }}</p>
        </label>
        <input class="input bd-radius" type="text" v-model="from" />
      </div>
      <div class="filter-item to-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx5">{{ $t("TOURISM.TO") }}</p>
        </label>
        <vue-select
          :options="arrival.options"
          class="input bd-radius cus-select border-option-dropdown"
          v-model="arrival.selected"
        ></vue-select>
      </div>
      <div class="filter-item range-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/calendar-alt-light-white.svg" alt="">
          <p class="mx5">{{ $t("COMMON.DATES") }}</p>
        </label>
        <date-range-picker v-model="dateRange" class="range-datepicker">
        </date-range-picker>
      </div>
      <div class="filter-item travelers-input p-relative">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/people.svg" alt="">
          <p class="mx5">{{ $t("TOURISM.TRAVELERS") }}</p>
        </label>
        <input 
          class="input bd-radius text-center" 
          type="text" 
          v-model="totalPassenger" 
          @click="togglePassenger" 
          readonly
        />
        <div class="passengers-list bg-white p10 bd-radius" v-show="isShowPassenger">
          <p>
            <b>Passengers</b>
          </p>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/account.svg" alt="" class="user-icon">
              <div>
                <p><b>Adults</b></p>
                <p class="fs12">Obber 11</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('adults', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{adults}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('adults', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/face-man-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>Children</b></p>
                <p class="fs12">age 2-11</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('children', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{children}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('children', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/baby-face-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>Infants</b></p>
                <p class="fs12">Under 2</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('infants', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{infants}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('infants', '+')">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="button filter__button bd-radius" @click="clickFilterBtn()"></button>
    </section>
    <section class="mobile-filter-modal" v-show="isFilterModalVisible">
      <p class="fs20 color-white text-center m-b-10">{{ $t("TOURISM.SEARCH_YOUR_DESIRE") }}</p>
      <div class="filter-item m-t-15 deal-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/umbrella-beach-light.svg" alt="">
          <p class="mx5 color-white">{{ $t("TOURISM.TYPE_OF_DEAL") }}</p>
        </label>
        <vue-select
          :options="deal.options"
          class="input cus-select border-option-dropdown bd-radius"
          v-model="deal.selected"
          :placeholder="$t('COMMON.SELECT')"
        ></vue-select>  
      </div>
      <div class="filter-item m-t-15 from-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx5 color-white">{{ $t("TOURISM.FROM") }}</p>
        </label>
        <input class="input bd-radius" type="text" v-model="from" />
      </div>
      <div class="filter-item m-t-15 to-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/plane.svg" alt="">
          <p class="mx5 color-white">{{ $t("TOURISM.TO") }}</p>
        </label>
        <vue-select
          :options="arrival.options"
          class="input bd-radius cus-select border-option-dropdown"
          v-model="arrival.selected"
        ></vue-select>
      </div>
      <div class="filter-item m-t-15 range-input">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/calendar-alt-light-white.svg" alt="">
          <p class="mx5 color-white">{{ $t("COMMON.DATES") }}</p>
        </label>
        <date-range-picker v-model="dateRange" class="range-datepicker">
        </date-range-picker>
      </div>
      <div class="filter-item m-t-15 travelers-input p-relative">
        <label for="" class="d-flex align-items-center m-b-5">
          <img src="/assets/img/icons/people.svg" alt="">
          <p class="mx5 color-white">{{ $t("TOURISM.TRAVELERS") }}</p>
        </label>
        <input 
          class="input bd-radius text-center" 
          type="text" 
          v-model="totalPassenger" 
          @click="togglePassenger" 
          readonly
        />
        <div class="passengers-list bg-white p10 bd-radius" v-show="isShowPassenger">
          <p>
            <b>Passengers</b>
          </p>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/account.svg" alt="" class="user-icon">
              <div>
                <p><b>Adults</b></p>
                <p class="fs12">Obber 11</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('adults', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{adults}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('adults', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/face-man-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>Children</b></p>
                <p class="fs12">age 2-11</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('children', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{children}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('children', '+')">
                +
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between m-t-15">
            <div class="d-flex align-items-center">
              <img src="/assets/img/icons/baby-face-outline.svg" alt="" class="user-icon">
              <div>
                <p><b>Infants</b></p>
                <p class="fs12">Under 2</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="minus-btn df-c" @click="changePassangers('infants', '-')">
                -
              </div>
              <p class="df-c number-block">
                {{infants}}
              </p>
              <div class="plus-btn df-c" @click="changePassangers('infants', '+')">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center filter-action-block m-t-10">
        <button class="primary-btn" @click="clickFilterBtn()">
          {{ $t('REPORT_SALES.SEARCH') }}
          <img 
            src="/assets/img/icons/search-light-white.svg" 
            alt="" 
            class="seach-btn-img"
          >
        </button>
        <button 
          class="primary-btn bg-red6"  
          @click="closeFilterModal()"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: 'TourismFilter',
  props: {
    hasTypeDeal: {
      default: true,
      type: Boolean
    }
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 90);
    let night = ['1', '89'];

    return {
      deal: {
        options: [
          {
            code: '',
            label: this.$t("TOURISM.ALL")
          },
          {
            code: 'NOFSHON',
            label: this.$t("TOURISM.NOFSHON")
          },
          {
            code: 'ORGANIZED_TOUR',
            label: this.$t("TOURISM.ORGANIZED_TOUR")
          },
        ],
        selected: {
          code: '',
          label: this.$t("TOURISM.ALL")
        }
      },
      from: "TLV",
      arrival: {
        options: [],
        selected: '',
      },
      dateRange: { 
        startDate: startDate, 
        endDate: endDate, 
        night: night 
      },
      isShowPassenger: false,
      travelers: 0,
      adults: 0,
      children: 0,
      infants: 0,
      isFilterModalVisible: false
    }
  },
  computed: {
    totalPassenger() {
      return this.adults + this.children + this.infants;
    }
  },
  methods: {
    clickFilterBtn() {
    },
    togglePassenger() {
      this.isShowPassenger = !this.isShowPassenger;
    },
    changePassangers(pType, changeType) {
      if (changeType == "-" && this[pType] != 0) {
        this[pType] = this[pType] - 1;
      }
      if (changeType == "+") {
        this[pType] = this[pType] + 1;
      }
    },
    openFilterModal() {
      $("html").css("overflow", "hidden");
      this.isFilterModalVisible = true;
    },
    closeFilterModal() {
      $("html").css("overflow", "auto");
      this.isFilterModalVisible = false;
    }
  }
}
</script>

<style lang="scss">
.filter {
  .range-datepicker .reportrange-text {
    height: 38px;
    border-radius: 5px;
  }
}

.reportrange-text {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
</style>

<style lang="scss" scoped>
.filter {
  label {
    color: white;
  }
  .deal-input,
  .from-input,
  .to-input {
    width: 18%;
  }
  .travelers-input {
    width: 10%;
    .passengers-list {
      position: absolute;
      top: 80px;
      right: 0;
      font-size: 16px;
      width: 300px;
      z-index: 22;
      &:after {
        content: '';
        display: block;  
        position: absolute;
        right: 40px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-bottom: 15px solid #fff;
        border-top: 15px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
      .minus-btn {
        width: 30px;
        height: 30px;
        background-color: #F2F2F2;
      }
      .plus-btn {
        width: 30px;
        height: 30px;
        background-color: #D9D9D9;
      }
      .user-icon {
        width: 35px;
      }
      .number-block {
        width: 35px;
      }
    }
  }
  .range-input {
    width: 25%;
  }
  &.no-type-deal {
    .deal-input {
      display: none;
    }
    .from-input,
    .to-input {
      width: 22%;
    }
    .range-input {
      width: 32%;
    }
    .travelers-input {
      width: 15%;
    }
  }
}

.mobile-filter-buttons {
  display: none;
}

@media screen and (max-width: 800px) {
  .desktop-filter {
    display: none;
  }
  .mobile-filter-buttons {
    display: block;
    .primary-btn {
      background-color: #002036;
      width: auto;
      font-size: 12px;
      font-weight: initial;
      height: auto;
      padding: 5px;
      border-radius: 8px;
      .seach-btn-img {
        height: 15px;
        margin-right: 10px;
      }
    }
  }
  .mobile-filter-modal {
    position: fixed;
    top: 60px;
    width: 100vw;
    left: 0;
    right: 0;
    background-color: #002036;
    height: calc(100vh - 60px);
    padding: 15px;
    z-index: 22;
    overflow: auto;
    .filter__item {
      padding: 0;
      height: unset;
      margin-top: 15px;
      &::after {
        display: none;
      }
      .cus-select {
        height: 40px;
        padding-top: 0;
      }
    }
    .filter-action-block {
      .primary-btn {
        padding: 0 20px;
        margin: 0 10px;
        border-radius: 5px;
        .seach-btn-img {
          margin-right: 15px;
        }
      }
    }
  }
  .ltr-type {
     .mobile-filter-buttons {
      .primary-btn {
        .seach-btn-img {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
    .mobile-filter-modal {
      .filter-action-block {
        .primary-btn {
          .seach-btn-img {
            margin-right: unset;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>